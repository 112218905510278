$select-week-width: 32px;

.react-datepicker__navigation-icon {
  width: 0px; /* fix for safari */
}

.period-date-picker {
  width: 100%;
  border: none;
  font-family: var(--font-family);
  font-size: var(--font-size-normal);

  .react-datepicker__month-container {
    float: none;
  }

  .react-datepicker__day-names {
    margin: 0;
  }

  .react-datepicker__day-name {
    color: var(--grey-dark);
    text-transform: uppercase;
    height: 30px;
    line-height: 30px;
    margin: 0;
    width: 14.28%;
  }

  .react-datepicker__header {
    border-bottom: 1px solid var(--grey-light);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    padding: 0;
    background: var(--white);
  }

  .react-datepicker__current-month, .react-datepicker-year-header {
    background: var(--grey-xx-light);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-normal);
    text-transform: uppercase;
    height: 48px;
    line-height: 48px;
    border-bottom: 1px solid var(--grey-light);
    border-top: 1px solid var(--grey-light);
    color: var(--primary-color);
  }

  .react-datepicker__day {
    color: var(--primary-color);
    height: 50px;
    line-height: 50px;
    width: 14.28%;
    margin: 0;
    border-right: 1px solid var(--grey-light);
    box-sizing: border-box;
  }

  .react-datepicker__day:last-child {
    border: none;
  }

  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker__month-text {
    width: 33.2%;
    margin: 0;
    height: 50px;
    line-height: 50px;
    text-transform: uppercase;
  }

  .react-datepicker__day--outside-month, .react-datepicker__day--disabled {
    color: var(--grey-light);
  }

  .react-datepicker__day--keyboard-selected, .react-datepicker__month--keyboard-selected, .react-datepicker__year--keyboard-selected {
    background: none;
  }

  .react-datepicker__day--selected, .react-datepicker__day--in-range, .react-datepicker__month--selected, .react-datepicker__year-text--selected {
    background-color: var(--primary-base);
    color: var(--white);
    border-radius: 0;
  }

  .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__year-text:hover {
    border-radius: 0;
  }

  .react-datepicker__navigation {
    height: 48px;
  }

  .react-datepicker__year {
    margin: 0;
  }

  .react-datepicker__year-text {
    width: 25%;
    margin: 0;
    height: 50px;
    line-height: 50px;
  }

  .react-datepicker__year-wrapper {
    max-width: 100%;
    margin: 0;
  }
}

.select-week-mode {
  .react-datepicker__week-number {
    width: $select-week-width;
    margin: 0;
    line-height: 50px;
  }

  .react-datepicker__week {
    width: calc(100% - #{$select-week-width});
  }

  .react-datepicker__day-names {
    width: calc(100% - #{$select-week-width});
  }

  .react-datepicker__day-name:first-child {
    width: $select-week-width;
  }
}
