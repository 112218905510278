.tabs {
  display: flex;
}

.tab-item {
  height: 48px;
  line-height: 48px;
  flex: 1;
  text-align: center;
  color: var(--tab-color);
  text-decoration: none;
  text-transform: uppercase;
  background: var(--tab-bg-color);
  font-weight: var(--tab-font-weight);
  border-top: solid 1px var(--tab-border-color);
  border-right: solid 1px var(--tab-border-color);
  border-bottom: solid 1px var(--tab-border-color);
  box-sizing: border-box;
}

.tab-item:last-child {
  border-right: none;
}

.tab-item.active {
  color: var(--tab-active-color);
  background: var(--tab-active-bg-color);
  border-top: solid 2px var(--tab-active-border-color);
  border-bottom: none;
}
