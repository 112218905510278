.today-top-area {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
}

@media (max-width: 360px) {
  .today-top-area {
    .goal {
      width: 45px;
    }
  }
}