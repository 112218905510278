.rcb {
  height: 100vh;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.rcb-bg {
  fill: var(--white);
  stroke: var(--grey-light);
  stroke-width: 1px;
}

.rcb-progress-bg {
  fill: var(--primary-color);
  stroke: var(--primary-color);
  stroke-width: 1px;
}

.rcb-positive-balance-bg {
  fill: var(--negative);
  stroke: var(--negative);
  stroke-width: 1px;
}

.rcb-negative-balance-bg {
  fill: var(--positive);
  stroke: var(--positive);
  stroke-width: 1px;
}

.rcb-text-label {
  fill: var(--primary-color);
  font-size: var(--font-size-xxlarge);
  font-weight: var(--font-weight-bold);
  text-anchor: middle;
}

.rcb-subtext-label {
  fill: var(--grey-dark);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
  text-anchor: middle;
}

.rcb-text-label.completed {
  font-size: var(--font-size-xxlarge);
}

.rcb-subtext-label.completed {
  font-size: var(--font-size-xlarge);
}

@media (max-width: 360px) {
  .rcb-text-label {
    font-size: var(--font-size-large);
  }

  .rcb-text-label.completed {
    font-size: var(--font-size-xlarge);
  }
  
  .rcb-subtext-label.completed {
    font-size: var(--font-size-large);
  }
}