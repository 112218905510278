.icon-arrow {
  width: 16px;
  height: 16px;
  background-size: 100%;
  vertical-align: middle;
  display: inline-block;
}

.icon-arrow.up {
  background: url('./icons/icon-arrow-up.svg') no-repeat;
}

.icon-arrow.down {
  background: url('./icons/icon-arrow-down.svg') no-repeat;
}

.icon-arrow.left {
  background: url('./icons/icon-arrow-left.svg') no-repeat;
}

.icon-arrow.right {
  background: url('./icons/icon-arrow-right.svg') no-repeat;
}