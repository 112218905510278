.today-goals-progress {
  .goals-title {
    text-align: right;
    text-transform: uppercase;
    color: var(--today-goal-title-color);
    font-weight: var(--today-goal-title-font-weight);
    font-size: var(--today-goal-title-font-size);
    padding: 10px 0 5px 0;
  }

  .goal {
    width: 57px;
    height: 10px;
    display: inline-block;
    border-radius: 4px;
    box-sizing: border-box;
    margin-left:4px;
  }
}

.history-goals-progress {
  .goals-container {
    grid-gap: 4px;
    gap: 4px;
    display: flex;
  }

  .goals-title {
    color: var(--card-title-color);
    font-size: var(--card-title-font-size);
    font-weight: var(--card-title-font-weight);
    padding-bottom: 20px;
  }

  .goal {
    flex: 1 1;
    height: 10px;
    display: inline-block;
    border-radius: 4px;
    box-sizing: border-box;
  }
}

.goal-outline {
  border: 1px solid var(--goal-outline-border-color);
  background-color: var(--goal-outline-bg-color);
}

.goal-outline-positive {
  border: 1px solid var(--goal-outline-positive-border-color);
  background-color: var(--goal-outline-positive-bg-color);
}

.goal-outline-negative {
  border: 1px solid var(--goal-outline-negative-border-color);
  background-color: var(--goal-outline-negative-bg-color);
}

.goal-positive {
  border: 1px solid var(--goal-positive-border-color);
  background-color: var(--goal-positive-bg-color);
}

.goal-negative {
  border: 1px solid var(--goal-negative-border-color);
  background-color: var(--goal-negative-bg-color);
}