@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&amp;subset=latin-ext");

body {
  font-family: var(--font-family);
  font-size: 12px;
  background-color: var(--grey-x-light);
  max-width: 1024px;
  overflow-x: hidden;
  margin: 0 auto;
}

button {
  font-family: var(--font-family);
}

.positive-text {
  color: var(--positive);
}

.negative-text {
  color: var(--negative);
}

.primary-text {
  color: var(--primary-color);
}

.secondary-text {
  color: var(--grey-dark);
}

.flex-gap {
  gap: 15px;
  display: flex;
}

.flex-gap-item {
  flex: 1 1;
}

.h-100 {
  height: 100%;
}

.fadein {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 1s; /* Firefox < 16 */
      -ms-animation: fadein 1s; /* Internet Explorer */
       -o-animation: fadein 1s; /* Opera < 12.1 */
          animation: fadein 1s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}