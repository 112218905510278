.radial-progress-bar {
  height: 100vh;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.progress-bar-le100 {
  fill: var(--primary-color);
  stroke: var(--primary-color);
  stroke-width: 1px;
}

.progress-bar-bg-le100 {
  fill: var(--negative);
  stroke: var(--grey-light);
  stroke-width: 1px;
}

.progress-bar-gt100 {
  fill: var(--positive);
  stroke: var(--positive);
  stroke-width: 1px;
}

.progress-bar-bg-gt100 {
  fill: var(--primary-color);
  stroke: var(--primary-color);
  stroke-width: 1px;
}

.actual-label {
  fill: var(--primary-color);
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
  text-anchor: middle;
}


.expected-label {
  fill: var(--grey-dark);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
  text-anchor: start;
}

.actual-label.completed {
  font-size: var(--font-size-xxlarge);
}


.expected-label.completed {
  font-size: var(--font-size-xlarge);
}