$tooltip-width: 83px;

.gph-bar-container {
  position: relative;
  padding: 15px 0;
  height: 35px;
}

.gph-bar {
  height: 16px;
  border-radius: 8px;
  box-sizing: border-box;
}

.gph-negative-bar {
  position: absolute;
  left: 0;
  width: 100%;
  border: solid 1px var(--negative-d20);
  background-color: var(--negative);
  z-index: 1;
}

.gph-alert-bar {
  left: 25%;
  width: 75%;
  position: absolute;
  border: solid 1px var(--alert-d20);
  background-color: var(--alert);
  z-index: 2;
}

.gph-positive-bar {
  left: 50%;
  width: 50%;
  position: absolute;
  border: solid 1px var(--positive-d20);
  background-color: var(--positive);
  z-index: 3;
}

.gph-tooltip {
  position: absolute;
  top: 25px;
  z-index: 4;
}

.gph-negative-tooltip {
  left: calc(12.5% - #{$tooltip-width/2});

  .gph-tooltip-fill {
    fill: var(--negative);
  }

  .gph-tooltip-stroke {
    fill: var(--negative-d20);
  }
}

.gph-alert-tooltip {
  left: calc(37.5% - #{$tooltip-width/2});

  .gph-tooltip-fill {
    fill: var(--alert);
  }

  .gph-tooltip-stroke {
    fill: var(--alert-d20);
  }
}

.gph-positive-tooltip {
  left: calc(75% - #{$tooltip-width/2});
  .gph-tooltip-fill {
    fill: var(--positive);
  }

  .gph-tooltip-stroke {
    fill: var(--positive-d20);
  }
}

.gph-tooltip-text {
  position: absolute;
  color: var(--white);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-large);
  width: $tooltip-width;
  text-align: center;
  top: 18px;
}