.greeting {
  color: var(--greeting-color);
  font-weight: var(--greeting-font-weight);
  font-size: var(--greeting-font-size);
  text-transform: uppercase;
  padding-bottom: 7px;
}

.greeting-name {
  color: var(--greeting-name-color);
  font-weight: var(--greeting-name-font-weight);
  font-size: var(--greeting-name-font-size);
}