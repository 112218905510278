.two-column-row {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  line-height: 25px;
}

.two-column-row-label {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
}

.two-column-row-value {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
}
