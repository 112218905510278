.history-day-header {
  font-size: var(--font-size-normal);
  font-weight: var(--font-weight-bold);
  color: var(--grey-dark);
  padding: 40px 0 16px 0;
}

.history-day-header:first-child {
  padding-top: 0;
}

.history-day-divider {
  border-bottom: solid 1px var(--grey-light);
  padding-top: 40px;
}

.history-day-divider:last-child {
  display: none;
}
