.button-select-list {
  display: flex;
  margin-bottom: 8px;
  box-shadow: inset 0px 0px 4px rgba(0,0,0,0.2);
  background: var(--bsl-bg);
  border-radius: 4px;
  border: 1px solid var(--bsl-border-color);

  button {
    flex: 1 1;
    height: 40px;
    border: none;
    border-right: 1px solid var(--bsl-border-color);
    box-sizing: border-box;
    color: var(--bsl-color);
    font-size: var(--bsl-font-size);
    font-weight: var(--bsl-font-weight);
    cursor: pointer;
    text-transform: uppercase;
    background: transparent;
    margin: 0;
  }

  button.selected {
    background: var(--bsl-selected-bg);
    box-sizing: border-box;
    box-shadow: none;
    color: var(--bsl-selected-color);
  }

  button.selected:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  button.selected:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  button:last-child {
    border-right: none;
  }
}
