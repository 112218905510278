$period-select-list-btn-size: 40px;

.period-select-list-nav {
  width: $period-select-list-btn-size;
  height: $period-select-list-btn-size;
  display: inline-block;
  vertical-align: top;
  background: var(--psl-bg);
  border: 1px solid var(--psl-border-color);
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
}

.period-select-list-nav:disabled {
  background: var(--psl-disabled-bg);
}

.period-select-list {
  position: relative;
  height: $period-select-list-btn-size;
  display: inline-block;
  width: calc(100% - #{2*$period-select-list-btn-size + 16px});
  margin: 0 8px 0 8px;
}

.period-select-list-dropdown-btn {
  position: absolute;
  width: 100%;
  height: $period-select-list-btn-size;
  background: var(--psl-bg);
  border: 1px solid var(--psl-border-color);
  box-sizing: border-box;
  border-radius: 4px;
  color: var(--primary-color);
  font-size: var(--psl-font-size);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &.open {
    border-bottom: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  label,
  i {
    pointer-events: none;
  }
}

.period-select-list-dropdown {
  position: absolute;
  top: 32px;
  width: 100%;
  background: var(--psl-bg);
  border: 1px solid var(--psl-border-color);
  box-sizing: border-box;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: none;
  z-index: 10;

  &>button {
    border: none;
    width: 100%;
    height: 32px;
    background: var(--psl-bg);
    cursor: pointer;
    text-align: left;
    font-size: var(--font-size-medium);

    &:hover {
      background: var(--psl-hover-bg);
    }

    &.selected {
      background: var(--psl-selected-bg);
      color: var(--psl-selected-color)
    }
  }
}
