.target-bar-chart {
  .axis {
    path,
    line {
      stroke-dasharray: 5 5;
      opacity: 0.2;
    }

    .tick text {
      fill: var(--grey-dark);
    }
  }

  .bar-positive {
    fill: var(--positive);
  }

  .bar-negative {
    fill: var(--negative);
  }

  .bar {
    fill: var(--bar-chart-color);
  }

  .bar-expected {
    fill: rgba(0, 0, 0, 0.1);
  }

  .line-expected {
    stroke: var(--primary-color);
    stroke-width: 2;
  }

  .x-grid,
  .y-grid {
    stroke-dasharray: 5 5;
    opacity: 0.2;
  }

  .x-grid .domain {
    opacity: 0;
  }
}
