@media (max-width: 600px) {
  .gallons-delivered-completed {
    .goal-header {
      min-height: 40px;
    }
  }
}

@media (max-width: 400px) {
  .gallons-delivered-completed {
    .goal-header {
      min-height: 50px;
    }
  }
}