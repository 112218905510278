.gallons-delivered-per-order {
  flex-direction: row;
  padding: 15px 0;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.gallons-delivered-per-order::-webkit-scrollbar {
  display: none;
}

.gallons-delivered-order {
  display: inline-block;
  width: 80px;
  height: 110px;
  margin: 0 8px 0 0;
  padding: 10px 8px;
}

.gallons-delivered-order-number {
  overflow: hidden;
  white-space: pre-wrap;
  height: 15px;
  line-height: 10px;
  text-align: center;
  padding-bottom: 5px;
  margin-bottom: 5px;
  font-size: var(--font-size-small);
  color: var(--grey-dark);
  font-weight: var(--font-weight-bold);
}
