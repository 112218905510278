.goal-status {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  border: solid 1px var(--grey-light);
  box-sizing: border-box;
  text-align: center;
  padding-top: 3px;
}

.goal-status-outline-positive {
  svg path {
    fill: var(--positive);
  }
}

.goal-status-outline-negative {
  svg path {
    fill: var(--negative);
  }
}

.goal-status-positive {
  border: solid 1px var(--positive-d20);
  background-color: var(--positive);
  svg path {
    fill: var(--white);
  }
}

.goal-status-negative {
  border: solid 1px var(--negative-d20);
  background-color: var(--negative);
  svg path {
    fill: var(--white);
  }
}
